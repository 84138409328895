<template>
  <VuePerfectScrollbar :settings="settings" >
	<div
		class="vx-row h-screen lg:flex w-full no-gutter"
		id="page-login"
    :style="eventFont"
		>
		<div
			v-if="isSidebarEnabled"
			class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/4 sm:m-0 h-full hidden lg:block">
			<div class="flex justify-center h-full w-full">
				<!-- QR Details -->
				<display-qr-details class="fixed" :event="event" />
			</div>
		</div>
		<div
			:class="`${!isSidebarEnabled ? 'w-full lg:w-full' : 'w-full lg:w-3/4'}`"
      :style="{ backgroundColor: !isCleanDisplay && event && event.base_color ? event.base_color : 'transparent', 'background-image' : `url('${eventBackground}')` }"
			class="vx-col sm:w-1/1 xs:w-1/1 lg:w-3/4 sm:m-0 ">
			<div class="flex h-full w-full">
        <transition name="zoom-in" enter-active-class="zoom-in-enter-active" mode="out-in">
				<!-- Question List -->
          <display-question-list
            v-if="(isViewQuestionList && !isRouteNameHighlight && !isFetching) || (isViewQuestionHighlight && !isRouteNameHighlight && !isFetching)"
            :question="question"
            :eventId="eventId"
            :eventSettings="eventSettings"
            :eventLang="eventLang"
            :event="event"
            :questionLimit="qLimit"
            @onHighlightActive="onHighlightActive"
          />

				<!-- Question Highlight -->
          <display-question-highlight
            v-if="isRouteNameHighlight && !isFetching"
            :event="event"
            :eventSettings="eventSettings"
            :question="questionHighlight"
            @onHighlightDeactive="onHighlightDeactive"
            :isQrEnabled="isQrEnabled"
            :isViewQuestionHighlight="isViewQuestionHighlight"
          />

				<!-- Poll Question -->
          <display-poll-question
            v-if="isViewQuestionPollingQuestion && !isRouteNameHighlight && !isFetching"
            :event="event"
            :eventSettings="eventSettings"
            :voting="voting"
          />

				<!-- Poll Result -->
          <display-poll-result
            v-if="isViewQuestionPollingResult && event && !isRouteNameHighlight && !isFetching"
            :event="event"
            :eventSettings="eventSettings"
            :voting="voting"
          />
        </transition>
        </div>
		</div>
	</div>
  </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import DisplayQrDetails from '@/components/events/display/DisplayQrDetails.vue';
import DisplayQuestionList from '@/components/events/display/DisplayQuestionList.vue';
import DisplayQuestionHighlight from '@/components/events/display/DisplayQuestionHighlight.vue';
import DisplayPollQuestion from '@/components/events/display/DisplayPollQuestion.vue';
import DisplayPollResult from '@/components/events/display/DisplayPollResult.vue';
import eventApi from '@/api/event';
import questionApi from '@/api/question';
import votingApi from '@/api/voting';

const VIEW_QUESTION_LIST = 'question_list';
const VIEW_QUESTION_HIGHLIGHT = 'question_hightlight';
const VIEW_QUESTION_POLLING_QUESTION = 'polling_question';
const VIEW_QUESTION_POLLING_RESULT = 'polling_result';

export default {
  components: {
    DisplayQrDetails,
    DisplayQuestionList,
    DisplayQuestionHighlight,
    DisplayPollQuestion,
    DisplayPollResult,
    VuePerfectScrollbar,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      event: null,
      question: [],
      view: VIEW_QUESTION_LIST,
      isQuestionListReady: false,
      qLimit: 999,
      questionHighlight: null,
      voting: null,
      settings: {
        maxScrollbarLength: 200,
      },
      isCleanDisplay: false,
      isFetching: false,
    };
  },
  sockets: {
    events_update(payload) {
      console.log('event', payload);
      Object.assign(this.event, payload);
    },
    votings_update(voting) {
      if (this.voting.id === voting.id) {
        this.voting = voting;
      }
      this.checkVotingStatus(this.voting);
    },
    votings_active(voting) {
      this.voting = voting;
      this.checkVotingStatus(this.voting);
    },
    votings_stop() {
      this.view = VIEW_QUESTION_LIST;
      localStorage.setItem('view', this.view);
      // this.updatePublish(voting);
    },
    votings_results(voting) {
      this.voting = voting;
      this.checkVotingStatus(this.voting);
      // this.view = VIEW_QUESTION_POLLING_RESULT;
      // this.updateVoting(voting);
    },
    questions_highlight(question) {
      if (question.is_highlight) {
        this.onHighlightActive(question);
      } else {
        this.onHighlightDeactive(question);
      }
    },
  },
  created() {
    // read from route slug eventSlug from vue router
    const eventSlug = this.$route.params.eventSlug;
    this.isCleanDisplay = this.$route.name === 'EventDisplayClean';
    this.getEventBySlug(eventSlug);
  },
  computed: {
    isViewQuestionList() {
      return this.view === VIEW_QUESTION_LIST && this.isQuestionListReady;
    },
    isViewQuestionHighlight() {
      return this.view === VIEW_QUESTION_HIGHLIGHT;
    },
    isViewQuestionPollingQuestion() {
      return this.view === VIEW_QUESTION_POLLING_QUESTION;
    },
    isViewQuestionPollingResult() {
      return this.view === VIEW_QUESTION_POLLING_RESULT;
    },
    eventId() {
      return this.event && this.event.id ? this.event.id : null;
    },
    eventSettings() {
      const eventSetting = this.event && this.event.settings ? JSON.parse(this.event.settings) : null;
      return eventSetting;
    },
    eventLang() {
      const langSetting = this.event && this.event.language ? this.event.language : 'en';
      this.updateLocale(langSetting);
      return langSetting;
    },
    eventBackground() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.background ? this.eventSettings.style.background : null;
    },
    eventFont() {
      const font = this.eventSettings && this.eventSettings.style && this.eventSettings.style.display_font ? this.eventSettings.style.display_font : 'Roboto';
      return `font-family: ${font}`;
    },
    isSidebarEnabled() {
      const isNoSidebarUrl = this.$route.name === 'EventDisplayWithoutSidebar';
      const isSidebarUrl = this.$route.name === 'EventDisplayWithSidebar';
      if (isNoSidebarUrl) return false;
      if (isSidebarUrl) return true;
      return this.eventSettings && this.eventSettings.sidebar && this.eventSettings.sidebar.is_display_presentation_sidebar;
    },
    isQrEnabled() {
      return this.eventSettings && this.eventSettings.sidebar && this.eventSettings.sidebar.is_qr_code;
    },
    isQuestionPane() {
      return this.eventSettings && this.eventSettings.sidebar && this.eventSettings.sidebar.is_display_question_pane;
    },
    isModerated() {
      return this.eventSettings && this.eventSettings.question_answer && this.eventSettings.question_answer.is_moderated;
    },
    room() {
      return this.eventId ? `event_${this.eventId}` : '';
    },
    isRouteNameHighlight() {
      let isHighlight = false;
      isHighlight = this.$route.name === 'EventDisplayHighlight';
      return isHighlight;
    },
    isControl() {
      return this.$route.name === 'EventDisplayControl';
    },
    isSidebar() {
      return this.$route.name === 'EventDisplayWithSidebar';
    },
    isWSidebar() {
      return this.$route.name === 'EventDisplayWithoutSidebar';
    },
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      this.$store.dispatch('setLocale', locale);
    },
    initSocket() {
      // Join Room
      this.$socket.client.emit('join', this.room);
    },
    checkVotingStatus(voting) {
      const ishighlightView = this.$route.name === 'DisplayQuestionHighlight';
      if (!ishighlightView) {
        if (voting.is_published) {
          if (voting.is_hide_live_result) {
            this.view = VIEW_QUESTION_POLLING_QUESTION;
          } else {
            this.view = VIEW_QUESTION_POLLING_RESULT;
          }
        } else {
          this.view = VIEW_QUESTION_LIST;
        }
        localStorage.setItem('voting', JSON.stringify(voting));
        localStorage.setItem('view', this.view);
      }
    },
    onHighlightActive(item) {
      this.questionHighlight = item;
      this.view = VIEW_QUESTION_HIGHLIGHT;
      localStorage.setItem('question', JSON.stringify(item));
      localStorage.setItem('view', this.view);
      for (let index = 0; index < this.question.data.length; index++) {
        if (this.question.data[index].id !== item.id) {
          this.question.data[index].is_highlight = 0;
        }
      }
    },
    onHighlightDeactive() {
      this.view = VIEW_QUESTION_LIST;
      // this.getquestion(this.event.id);
      localStorage.setItem('view', this.view);
    },
    getActiveVoting(eventId) {
      // get active voting by event id using votingApi
      const callback = (response) => {
        const voting = response.data;
        this.voting = voting;
        this.checkVotingStatus(voting);
      };
      const errCallback = () => {
        this.view = VIEW_QUESTION_LIST;
        localStorage.setItem('view', this.view);
      };
      votingApi.getActive(eventId, callback, errCallback);
    },
    getEventBySlug(eventSlug) {
      // get event by slug using eventApi
      this.isFetching = true;
      const callback = (response) => {
        this.event = response.data;
        this.updateLocale(this.event.language);
        let prefix = 'Present - ';
        if (this.isControl) prefix = 'Moderator Control - ';
        if (this.isWSidebar) prefix = 'Question - ';
        if (this.isSidebar) prefix = 'Question Sidebar - ';
        window.document.title = `${prefix} ${this.event.name}`;
        this.getquestion(this.event.id);
        this.initSocket();
        const localView = localStorage.getItem('view');
        const localQuestion = localStorage.getItem('question');
        const localVoting = localStorage.getItem('voting');
        this.voting = JSON.parse(localVoting);
        this.questionHighlight = JSON.parse(localQuestion);
        if (localView && (localQuestion || localVoting)) {
          if ((this.voting && this.voting.event_id) && this.voting.event_id === this.event.id) {
            this.view = localStorage.getItem('view') || VIEW_QUESTION_LIST;
          }
          if ((this.questionHighlight && this.questionHighlight.event_id) && this.questionHighlight.event_id === this.event.id) {
            this.view = localStorage.getItem('view') || VIEW_QUESTION_LIST;
          }
        }
        this.getActiveVoting(this.event.id);
        this.isFetching = false;
      };
      const errorCallback = (error) => {
        console.log(error);
        this.isFetching = false;
      };
      eventApi.getBySlug(eventSlug, callback, errorCallback);
    },
    getquestion(id) {
      this.isQuestionListReady = false;
      // get question using questionApi using event.id
      const callback = (response) => {
        this.question = response;
        if (this.question && this.question.data) {
          setTimeout(() => {
            for (let index = 0; index < this.question.data.length; index++) {
              const element = this.question.data[index];
              if (element && element.is_highlight) this.view = VIEW_QUESTION_HIGHLIGHT;
            }
          }, 1000);
        }
        this.isQuestionListReady = true;
      };
      const errorCallback = (err) => {
        console.log(err);
        this.isQuestionListReady = false;
      };
      const params = {
        limit: this.qLimit,
        is_archived: 0,
        order_by: 'created_at',
        sort_by: 'desc',
      };
      if (this.isModerated) {
        params.is_published = 1;
      }
      if (this.eventId) {
        questionApi.list(id, params, callback, errorCallback);
      }
    },
  },
  mounted() {

  },
};
</script>
<style>
/* Enter transition */
.zoom-in-enter-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.zoom-in-enter {
  opacity: 0;
  transform: scale(0.5);
}

/* Leave transition (disabled) */
/* No CSS rules are necessary for the leave transition */

</style>
